import React from "react";
import Label from "../../components/ui/label";
import IndustriesImg from "../../assets/images/Industries2.png";
import { useTranslation } from "react-i18next";

export default function Industries() {
  const { t } = useTranslation("jobs");

  return (
    <div className="mb-40 md:mb-60">
      <div className="flex flex-col md:flex-row items-center container mt-24 gap-14 lg:gap-20 lg:px-24">
        <div className="flex-1">
          <div className="max-w-[530px]">
            <Label
              labelText={t('industries')}
              className={"ml-auto mr-auto md:mr-auto md:ml-0"}
            />
            <h4 className="text-heading4 md:text-heading3 text-black my-10 text-center md:text-left">
              {t('heading_industries')}
            </h4>
            <p className="text-paragraph text-center md:text-left">
              {t('paragraph_industries')}
            </p>
          </div>
        </div>
        <div className="flex-1">
          <img
            src={IndustriesImg}
            alt={'IndustriesImg'}
            className="mx-auto ml-auto"
          />
        </div>
      </div>
    </div>
  );
}
