import React from "react";
import { useForm } from "react-hook-form";
import { useUserIdentifier } from "../../../context/userIdentifierContext";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

import Label from "../../../components/ui/label";
// import Button from "../../../components/ui/button";
import { useStep } from "../../../context/onboardingStepContext";

export default function Location() {
  const onboardingInfo = JSON.parse(localStorage.getItem("onboardingInfo"));
  const { setStep } = useStep();
  const { userIdentifier } = useUserIdentifier();
  const { t } = useTranslation("onboarding");

  const form = useForm({
    defaultValues: {
      location: "",
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
  } = form;

  const onSubmit = (data) => {
    localStorage.setItem("onboardingInfo", JSON.stringify({
      ...onboardingInfo,
      location: data.location
    }))

    axios.post("/api/v1/notifications", {
      message: `User \`${Cookies.get('userIdentifier')}\` submitted information on \`Location\`.`,
      info: data,
    });

    setStep(4);
  };

  const handleRadioChange = (e) => {
    form.setValue("industry", e.target.value);
    onSubmit({ location: e.target.value });
  };

  return (
    <div className="mt-10 max-w-[460px]">
      <Label labelText={t("locationLabel")} />
      <h3 className="text-[30px] leading-[36px] font-medium mt-8 max-w-[380px]">
        {t("locationHeading")}
      </h3>
      {/* <p className="text-heading6 leading-[24px] mt-8 backdrop-blur-md">
      {t("employmentHistoryDescription")}
      </p> */}

      <form
        className="mt-10 grid grid-cols-1 gap-7"
        onSubmit={handleSubmit(onSubmit)}
      >
        {[
          { value: "Bayern", label: t("Bayern") },
          { value: "Berlin", label: t("Berlin") },
          { value: "Hessen", label: t("Hessen") },
          { value: "Niedersachsen", label: t("Niedersachsen") },
          { value: "Nordrhein-Westfalen", label: t("Nordrhein-Westfalen") },
          { value: "Sachsen", label: t("Sachsen") }
        ].map((option) => (
          <div key={option.value}>
            <label htmlFor={option.value}>
              <input
                type="radio"
                value={option.value}
                id={option.value}
                className="form-industry-radio"
                {...register("employment_years")}
                onChange={handleRadioChange}
              />
              <span className="cursor-pointer w-full block text-center md:w-auto md:inline md:text-left">
                {option.label}
              </span>
            </label>
          </div>
        ))}

        {/* <div>
          <Button
            variant="primary"
            className={"w-full"}
            type={"submit"}
            disabled={!isDirty || !isValid}
          >
            {t("continueButton")}
          </Button>
        </div> */}
      </form>
    </div>
  );

  return (
    <div className="mt-10 max-w-[460px]">
      <Label labelText={t("industryLabel")} />
      <h3 className="text-[30px] leading-[36px] font-medium mt-10 max-w-[380px]">
        {t("employmentHistoryHeading")}
      </h3>

    </div>
  );
}
