import React, { useState, useEffect, useCallback } from 'react';

const iconBaseUrl = '/images';

export default function Sponsors() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(4);

  const updateSlidesToShow = useCallback(() => {
    if (window.innerWidth < 480) {
      setSlidesToShow(1);
    } else if (window.innerWidth < 768) {
      setSlidesToShow(2);
    } else if (window.innerWidth < 1024) {
      setSlidesToShow(4);
    } else {
      setSlidesToShow(6);
    }
  }, []);

  const logos = [
    {
      url: `${iconBaseUrl}/home/Ettenhuber_logo.svg`,
      alt: 'Ettenhuber logo',
      width: 43,
      height: 32,
      className: 'lg:w-[44px] lg:h-[33px]',
    },
    {
      url: `${iconBaseUrl}/home/Fiege_Logo.svg`,
      alt: 'Fiege logo',
      width: 81,
      height: 24,
      className: 'lg:w-[112px] lg:h-[33px]',
    },
    {
      url: `${iconBaseUrl}/home/Ideal_group_logo.svg`,
      alt: 'Ideal_group_logo',
      width: 49,
      height: 37,
      className: 'lg:w-[69px] lg:h-[51px]',
    },
    {
      url: `${iconBaseUrl}/home/Metallbau_logo.png`,
      alt: 'Metallbau_logo',
      width: 72,
      height: 44,
      className: 'lg:w-[92px] lg:h-[55px]',
    },
    {
      url: `${iconBaseUrl}/home/Gfkk_logo.png`,
      alt: 'Gfkk_logo',
      width: 78,
      height: 11,
      className: 'lg:w-[78px] lg:h-[78px]',
    },
    {
      url: `${iconBaseUrl}/home/Vollmer-logo.svg`,
      alt: 'Vollmer',
      width: 92,
      height: 24,
      className: 'lg:w-[129px] lg:h-[33px]',
    },
    {
      url: `${iconBaseUrl}/home/Huffermann_logo.svg`,
      alt: 'Huffermann_logo',
      width: 190,
      height: 24,
      className: 'lg:w-[177px] lg:h-[22px]',
    },
    {
      url: `${iconBaseUrl}/home/bwk_logo.jpg`,
      alt: 'Bwk_logo',
      width: 50,
      height: 15,
      className: 'lg:w-[60px] lg:h-[50px]',
    },
    {
      url: `${iconBaseUrl}/home/WISAG_logo.svg`,
      alt: 'WISAG_logo',
      width: 50,
      height: 15,
      className: 'lg:w-[100px] lg:h-[50px]',
    },
    {
      url: `${iconBaseUrl}/home/krw.png`,
      alt: 'krw',
      width: 50,
      height: 15,
      className: 'lg:w-[60px] lg:h-[50px]',
    },
    {
      url: `${iconBaseUrl}/home/SH.png`,
      alt: 'S+H',
      width: 100,
      height: 15,
      className: 'lg:w-[100px] lg:h-[25px]',
    },
    {
      url: `${iconBaseUrl}/home/TALKE.png`,
      alt: 'Talke',
      width: 100,
      height: 75,
      className: 'lg:w-[70px] lg:h-[30px]',
    },
    {
      url: `${iconBaseUrl}/home/Futron.png`,
      alt: 'Futron',
      width: 100,
      height: 75,
      className: 'lg:w-[70px] lg:h-[30px]',
    },
    {
      url: `${iconBaseUrl}/home/Freier_Group.png`,
      alt: 'Freier_Group',
      width: 100,
      height: 75,
      className: 'lg:w-[120px] lg:h-[90px]',
    },
    {
      url: `${iconBaseUrl}/home/Holthoff.jpg`,
      alt: 'Holthoff',
      width: 100,
      height: 75,
      className: 'lg:w-[50px] lg:h-[50px]',
    },
    {
      url: `${iconBaseUrl}/home/Logo-Wimmer.png`,
      alt: 'Wimmer',
      width: 100,
      height: 75,
      className: 'lg:w-[80px] lg:h-[50px]',
    },
  ];

  useEffect(() => {
    updateSlidesToShow();
    window.addEventListener('resize', updateSlidesToShow);
    return () => window.removeEventListener('resize', updateSlidesToShow);
  }, [updateSlidesToShow]);

  useEffect(() => {
    const containerElement = document.getElementById('logo-container');
    if (containerElement) {
      setContainerWidth(containerElement.scrollWidth / 2);
    }
  }, [logos, slidesToShow]);

  useEffect(() => {
    const scrollSpeed = 1;
    let animationFrameId;

    const scroll = () => {
      setScrollPosition((prevPosition) => {
        const newPosition = prevPosition + scrollSpeed;
        return newPosition >= containerWidth ? 0 : newPosition;
      });
      animationFrameId = requestAnimationFrame(scroll);
    };

    animationFrameId = requestAnimationFrame(scroll);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [containerWidth]);

  const carouselStyle = {
    width: '100%',
    overflow: 'hidden',
  };

  const logoContainerStyle = {
    paddingTop: '50px',
    display: 'flex',
    transform: `translateX(-${scrollPosition}px)`,
  };

  const logoSlideStyle = {
    flex: `0 0 ${100 / slidesToShow}%`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10px',
  };

  const duplicatedLogos = [...logos, ...logos];

  return (
    <div style={carouselStyle}>
      <div id="logo-container" style={logoContainerStyle}>
        {duplicatedLogos.map((item, idx) => (
          <div key={idx} style={logoSlideStyle}>
            <img width={item.width} height={item.height} src={item.url} alt={item.alt} className={item.className} />
          </div>
        ))}
      </div>
    </div>
  );
}
