import React, { useState, useEffect } from "react";

import OnboardingLayouts from "../../components/layouts/OnboardingLayouts";
import OnboardingLeftContent from "./OnboardingLeftContent";
import OnboardingStepRenderer from "./OnboardingStepRenderer";
import { useStep } from "../../context/onboardingStepContext";
import { useTranslation } from "react-i18next";

export default function Onboarding() {
  const { step } = useStep();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("onboarding");

  useEffect(() => {
    if (step == 4) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);
  
      return () => clearTimeout(timer); 
    }
  }, [step])

  return (
    <OnboardingLayouts>
      {
        loading ? (
          <div className="flex flex-col items-center mt-[60px] md:mt-[90px]">
            <div className="w-16 h-16 border-4 border-blue-500 border-e rounded-full animate-spin mt-32 md:mt-48"></div>
            <p className="text-[30px] md:text-[40px] text-center mx-6 mt-8 mb-32 md:mb-48">{t("findingJobs")}</p>
          </div>
        ) : (
          <div
            className={`grid grid-cols-1 ${
              (step === 5 || step === 6) ? "" : "md:grid-cols-2 mb-20"
            } gap-24 md:gap-0 md:mb-40`}
          >
            {step !== 5 ? <OnboardingLeftContent /> : null}
            <OnboardingStepRenderer />
          </div>
        )
      }
    </OnboardingLayouts>
  );
}
