import React from "react";
import Button from "../../components/ui/button";

import PointerIcon from "../../assets/images/pointer.png";
import CurrencyIcon from "../../assets/images/currency.png";
import CheckIcon from "../../assets/images/check.svg";
import { useTranslation } from "react-i18next";

export default function JobItem({ jobDetail }) {
  const { t } = useTranslation("jobs");

  return (
    <div className="relative">
      {jobDetail.isNew && (
        <p className="max-w-[50px] text-white bg-[#db1f35c7] text-sm leading-none tracking-tight px-4 py-1.5 rounded-md absolute top-6 left-5">
          {t("new")}
        </p>
      )}
      <div className="border border-solid border-black border-opacity-15 px-8 md:px-12 py-12 rounded-3xl">
        <h4 className="text-[22px] md:text-[26px] leading-8 tracking-tight">
          {jobDetail.title}
        </h4>

        <div className="flex flex-wrap flex-col items-start gap-4 mt-4 lg:">
          <span className="flex items-center px-3 py-1 gap-1 text-[#0061FF] rounded bg-[#0061FF] bg-opacity-10 text-xs md:text-sm">
            <img src={PointerIcon} className="w-3" /> {jobDetail.location}
          </span>
          <span className="flex items-center px-3 py-1 gap-1 text-[#F37426] rounded bg-[#F37426] bg-opacity-10 text-xs md:text-sm">
            <img src={CurrencyIcon} className="w-3" /> {jobDetail.rate}
          </span>
        </div>

        <div className="divider h-px border-t border-black my-4 border-opacity-10"></div>

        <div>
          {jobDetail.benefits.map((benefit, index) => (
            <div className="flex items-center gap-2 text-base mb-4" key={index}>
              <img src={CheckIcon} className="w-4" />
              {benefit}
            </div>
          ))}
        </div>
        <a href="/onboarding">
          <Button variant="primary" className="h-12 w-40">
            {t("applyNow")}
          </Button>
        </a>
      </div>
    </div>
  );
}
